import { Router, Route, Set } from '@redwoodjs/router'
import MainLayout from 'src/layouts/MainLayout'

const Routes = () => {
  return (
    <Router>
      <Route path="/share" page={SharePage} name="share" />
      <Set wrap={[MainLayout]}>
        <Route path="/" page={HomePage} name="home" prerender />
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
