type MainLayoutProps = {
  children?: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="wrapper flex-1 flex flex-col py-12">{children}</main>
    </div>
  )
}

export default MainLayout
